import ApiService from "./api.service";

// 分页返回详细数据
export function getSubAgent(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/agent/getSubAgent", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 仅返回简易数据
export function getAllSubAgent(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/agent/getAllSubAgent", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 获取代理商详情
export function getAgentDetail(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/agent/detail", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 添加或更新代理商
export function saveAgent(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/agent/save", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 获取指定日期的利润
export function getProfitByDate(date) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/agent/getProfitByDate", { date })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
