<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      :title="state.titleText"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-field
        v-if="!state.id"
        v-model="state.user_phone"
        label="用户手机"
        placeholder="请代理商先使用手机号登录本系统"
        type="number"
      />
      <van-field v-model="state.agent_name" label="代理名称" />
      <van-field v-model="state.contact_name" label="联系人" />
      <van-field v-model="state.contact_phone" label="联系电话" type="number" />
      <div class="py-4 action-button-area text-center">
        <van-button v-if="!canSubmit" type="primary" disabled>{{
          state.buttonText
        }}</van-button>
        <van-button v-else type="primary" @click="submitForm">{{
          state.buttonText
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, Toast } from "vant";
import { getAgentDetail, saveAgent } from "@/api/agent.service";
export default {
  name: "AgentForm",
  components: {
    [Field.name]: Field,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      // 有ID为更新, ID=0为添加
      id: 0,

      // 状态数据
      titleText: "加载中",
      buttonText: "加载中",
      loading: true,
    });

    const canSubmit = computed(() => {
      if (!state.agent_name || !state.contact_name || !state.contact_phone) {
        return false;
      }
      if (!state.id && !state.user_phone) {
        return false;
      }
      return true;
    });

    const loadForm = async (agent_id) => {
      try {
        const { result } = await getAgentDetail(agent_id);
        for (const key in result) {
          state[key] = result[key];
        }
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const submitForm = async () => {
      try {
        await saveAgent({
          id: state.id,
          user_phone: state.user_phone,
          agent_name: state.agent_name,
          contact_name: state.contact_name,
          contact_phone: state.contact_phone,
        });
        Toast.success("保存成功");
        router.back();
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        state.titleText = "添加代理商";
        state.buttonText = "确认添加";
        state.loading = false;
      } else {
        state.id = id;
        state.titleText = "更新代理商";
        state.buttonText = "确认更新";
        loadForm(id);
      }
    });

    return {
      state,
      canSubmit,
      submitForm,
    };
  },
};
</script>
